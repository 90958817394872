import React from "react";
import PropTypes from "prop-types";
import Image from "react-bootstrap/Image";
import Layout from "../../../../components/layout";
import OrderButton from "../../../../components/orderbutton";
import ProductTable from "../../../../components/productTable";
import SEO from "../../../../components/seo";
import { Col, Container, Row } from "react-bootstrap";
import Datasheet from "../../../../content/produk/controller/videoprocessor/COLORLIGHT X16/datasheet-colorlight-x16.pdf";
import Content from "../../../../content/produk/controller/videoprocessor/COLORLIGHT X16/Product-Page.json";
import ProductImage from "../../../../images/product/controller/videoprocessor/COLORLIGHT X16/Product-Summary-Colorlight-X16.jpg";
const colorlightx16 = () => (
    <Layout>
        <SEO
            title={Content.seo.title}
            description={Content.seo.description}
            lang={Content.seo.lang}
        />
        <section id={"produk"} className={"section-bg"}>
            <Container>
                <div className={"section-title"}>
                    <h1>Video Processor Colorlight X16</h1>
                </div>
                <Row>
                    <Col>
                        <Image
                            src={ProductImage}
                            fluid
                            alt={"Gambar modul"}
                        />
                    </Col>
                </Row>
                <Row className={"mt-5"}>
                    <Col>
                        <div className={"section-title"}>
                            <h2 className={"text-center"}>Product Summary</h2>
                        </div>
                        <p>
                            X16 is a professional LED display controller. It
                            possesses powerful video signal receiving, splicing
                            and processing capacities, and supports multiple
                            signal inputs, with the maximum input resolution of
                            4K@4096×2160 pixels. It supports digital ports
                            (HDMI, DVI, SDI), and seamless switching between
                            signals. It supports splicing, broadcast quality
                            scaling, and seven-pictures displays.
                        </p>
                        <ul>
                            <li>
                                Supports various digital signal ports, including
                                2×SDI, 1×HDMI2.0 and 4×DVI
                            </li>
                            <li>
                                Supports input resolutions up to 4096×2160@60Hz
                            </li>
                            <li>
                                Loading capacity: 8.88 million, maximum width:
                                8192 pixels，maximum height: 4096 pixels;
                            </li>
                            <li>
                                Supports arbitrary switching of video sources;
                                the input images can be spliced and scaled
                                according to the screen resolution;
                            </li>
                            <li>
                                Supports 7 PIPs, the location and size can be
                                adjusted freely;
                            </li>
                            <li>Supports HDCP 2.2;</li>
                            <li>
                                Dual USB2.0 for high speed configuration and
                                easy cascading among controllers;
                            </li>
                            <li>
                                Supports brightness and color temperature
                                adjustment;
                            </li>
                            <li>Supports better gray at low brightness；</li>
                            <li>
                                Compatible with all receiving cards,
                                multifunction cards, and optical fiber
                                transceivers of Colorlight
                            </li>
                        </ul>
                        <p>
                            <OrderButton
                                messageText={Content.messageText}
                                datasheet={Datasheet}
                            />
                        </p>
                    </Col>
                    <Col>
                        <div className={"section-title"}>
                            <h2 className={"text-center"}>Specification</h2>
                        </div>
                        <ProductTable
                            tableName={"LED Unit Cabinet"}
                            tableData={Content.specification}
                        />
                    </Col>
                </Row>
            </Container>
        </section>
    </Layout>
);

colorlightx16.propTypes = {
    siteTitle: PropTypes.string,
};

colorlightx16.defaultProps = {
    siteTitle: ``,
};
export default colorlightx16;
