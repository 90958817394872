import React from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";

class OrderButton extends React.Component {
    render() {
        let { messageText } = this.props;
        return (
            <React.Fragment>
                <Button
                    as={"a"}
                    href={`https://wa.me/6289506017899?text=${encodeURIComponent(
                        messageText.whatsapp
                    )}`}
                    aria-label="WhatsApp"
                    target={"_blank"}
                    rel={"noreferrer noopener"}
                    style={{
                        backgroundColor: "#25D366",
                        borderColor: "#25D366",
                    }}
                    size="md"
                    block
                >
                    <i className="fab fa-whatsapp fa-fw" />
                    Pesan Melalui WhatsApp
                </Button>
                <Button
                    as={"a"}
                    href={`mailto:info@ilife.id?subject=${messageText.email.subject}&body=${messageText.email.messageBody}`}
                    aria-label="Email"
                    target={"_blank"}
                    rel={"noreferrer noopener"}
                    variant="primary"
                    size="md"
                    block
                >
                    <i className="far fa-envelope fa-fw" />
                    Pesan Melalui Email
                </Button>
                {/*TODO refactor this datasheet part so that it renders conditionally only when datasheet props provided*/}
                <Button
                    as={"a"}
                    href={this.props.datasheet}
                    aria-label="WhatsApp"
                    target={"_blank"}
                    rel={"noreferrer noopener"}
                    style={{
                        backgroundColor: "#2383C4",
                        borderColor: "#2383C4",
                    }}
                    size="md"
                    block
                >
                    <i className="far fa-file-pdf fa-fw"></i>
                    Datasheet
                </Button>
            </React.Fragment>
        );
    }
}

OrderButton.propTypes = {
    messageText: PropTypes.exact({
        whatsapp: PropTypes.string,
        email: PropTypes.exact({
            subject: PropTypes.string,
            messageBody: PropTypes.string,
        }),
    }),
    datasheet: PropTypes.any,
};
OrderButton.defaultProps = {
    datasheet: "",
};

export default OrderButton;
