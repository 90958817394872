import React from "react";
import PropTypes from "prop-types";
import Table from "react-bootstrap/Table";

class ProductTable extends React.Component {
    constructor(props) {
        super(props);
    }
    renderHeader() {
        let header = Object.keys(this.props.tableData[0]);
        return header.map((key, index) => {
            // Make header smaller then capitalize the first letter only
            return <th key={index}>{`${key.toLowerCase().charAt(0).toUpperCase()}${key.slice(1)}`}</th>;
        });
    }
    renderRows() {
        return this.props.tableData.map((student, index) => {
            const { name, value } = student;
            return (
                <tr key={index}>
                    <td>{name}</td>
                    <td>{value}</td>
                </tr>
            );
        });
    }
    render() {
        return (
            <>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>{this.renderHeader()}</tr>
                    </thead>
                    <tbody>{this.renderRows()}</tbody>
                </Table>
            </>
        );
    }
}

ProductTable.propTypes = {
    tableName: PropTypes.string,
    tableData: PropTypes.array,
};
ProductTable.defaultProps = {
    tableName: ``,
    tableData: [],
};
export default ProductTable;
